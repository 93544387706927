.congrats-modal-wrapper {
  z-index: 99;
  overflow-x: auto;

  .congrats-modal {
    margin-top: 139px;
    margin-bottom: auto;
    width: 313px;
    background: #fff;
    box-shadow: 0 2px 3px #0000004D;
    border-radius: 9px;
    padding: 16px 20px;

    .congrats-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 13px;

      .congrats-banner {
        display: flex;
        width: 224px;
        height: 47px;
        margin-left: 20px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .dismiss {
      width: 16px;
      height: 16px;
      background: url("../../../assets/close_icon.svg");
      &:hover {
        background: url("../../../assets/close_icon_hover.svg");
        cursor: pointer;
      }
    }

    .congrats-description {
      width: 204px;
      font-size: 14px;
      margin: 0 auto 19px auto;
      color: $dove_gray;
      text-align: center;
    }

    .congrats-button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .congrats-button {
        font-size: 15px;
        color: $dove_gray;
        padding: 10px 28px 11px 28px;
        border: 1px solid $medium_silver;
        border-radius: 8px;
        background: #fff;

        &:hover {
          background-color: $light-primary;
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xs) {
  .congrats-modal-wrapper {
    .congrats-modal {
      width: 100%;
      padding: 24px 12px;
      border-radius: 0;
    }
  }
}
