.single-trait-wrapper {
  .page-title {
    width: 100%;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    color: $nile_blue;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: $break-point-lg) {
  .single-trait-wrapper {
    > div:first-child {
      display: none;
    }
  }
}
