.my-account {
  .account-info-container {
    background-color: #fff;
    border-radius: 12px;
    .info-header {
      width: 100%;
      padding: 12px 24px;
      border-radius: 8px 8px 0 0;
      background-color: $silver;
      font-size: 18px;
      font-weight: bold;
    }
    .info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 24px;

      ul {
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
        }
      }

      .account-edit-buttons {
        display: flex;
        align-items: flex-start;
      }
    }
  }
}
