.dna-registration-container {
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  background-image: url("../../assets/iTOVi_DNA_Home_BG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.dna-kit-registration {
  .dna-form-logo {
    width: 300px;
    height: 100px;
    margin-bottom: 22px;
  }
}

.dna-registration-form {
  padding: 20px;
  border-radius: 4px;
  background-color: $itovi_form_background;
  box-shadow: 0px 4px 6px $itovi_dna_dark_blue_gray;

  .btn-outline-success {
    color: #7cc24d !important;
    &:hover {
      color: #fff !important;
      background-color: #7cc24d;
    }
    &:focus {
      box-shadow: none !important;
    }
  }

  .btn-success {
    border: none;
  }
  .btn {
    height: 70px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 2px;
    &:hover {
      background-color: $itovi_dna_mint_green;
    }
    svg {
      float: right;
      font-size: 52px;
    }
  }

  input,
  select {
    border: 1px solid $itovi_dna_royal_purple;
    height: 60px;
    font-size: 26px;
    font-weight: 300;
    color: $itovi_dna_midnight_blue;

    &::placeholder {
      color: $itovi_dna_midnight_blue;
    }
  }

  p {
    color: $itovi_dna_midnight_blue;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
    color: $itovi_dna_midnight_blue;
  }
  .checkbox {
    & label {
      position: relative;
      cursor: pointer;
    }

    & label:before {
      content: "";
      background: url("../../assets/icon_box.svg");
      display: flex;
      height: 22px;
      width: 22px;
    }

    &:checked + label:after {
      content: "";
      display: flex;
      background: url("../../assets/icon_checked-box.svg");
      height: 22px;
      width: 22px;
    }

    &:checked + label:before {
      display: none;
    }
  }

  input[type="checkbox"] {
    outline: none;
    position: absolute;
    opacity: 0;
    margin: 0;
    height: 22px;
    width: 22px;
  }

  input[type="radio"] {
    outline: none;
    position: absolute;
    opacity: 0;
    margin: 0;
    height: 22px;
    width: 22px;

    &:not(:checked) + label:before {
      content: "";
      background: url("../../assets/icon_box.svg");
      display: flex;
      height: 22px;
      width: 22px;
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .dna-registration-container {
    padding: 24px 0;
  }
}
