.dna-whats-in-report-wrapper {
  padding: $dna_section_wrapper_padding;
  background: #fff;

  .dna-whats-in-report {
    margin: 0 auto;
    max-width: $dna_home_section;

    > div:last-child {
      display: flex;
      flex-direction: row;

      > div:first-child {
        max-width: 36%;
        margin: 0 5%;

        > img {
          width: 100%;
        }
      }

      > div:last-child {
        max-width: 59%;
        color: $itovi_dna_astronaut_blue;
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .dna-whats-in-report-wrapper {
    .dna-whats-in-report {
      > div:first-child {
        max-width: 100%;
      }

      > div:last-child {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin-top: 36px;

        > div:first-child {
          max-width: 100%;
          margin: 0 0 36px;
        }

        > div:last-child {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
