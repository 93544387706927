.side-navbar-wrapper {
  z-index: 1;
  height: 100%;

  .side-navbar {
    width: 64px;
    height: 100%;
    margin-right: 1px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 1px 0px 0px $silver;

    .header {
      width: 100%;
      height: 64px;
      padding: 8px;

      .nav-hamburger {
        padding: 8px;
        border: none;
        border-radius: 8px;
        background-color: #fff;

        @media (hover: hover) {
          &:hover {
            background-color: $link_water;
          }
        }

        &:focus {
          outline: none;
        }

        img {
          width: 32px;
          height: 32px;
          filter: invert(13%) sepia(30%) saturate(2234%) hue-rotate(190deg)
            brightness(95%) contrast(93%);
        }
      }

      .logo {
        width: 80px;
        height: 32px;
        margin-left: 8px;
        display: none;
      }
    }

    .links-container {
      width: 100%;
      padding: 8px;

      a {
        color: $dove_gray;
        text-decoration: none;
        font-weight: Medium;
        font-size: 18px;

        > div {
          display: flex;
          align-items: center;
          width: 100%;
          height: 48px;
          padding: 8px 14px;
          margin-bottom: 4px;
          border-radius: 8px;

          &:hover {
            background-color: $link_water;
          }

          img {
            width: 21px;
            height: 28px;
            filter: invert(35%) sepia(97%) saturate(7%) hue-rotate(340deg)
              brightness(92%) contrast(93%);
          }

          span {
            display: none;
            margin-left: 18px;
          }
        }
      }

      a.active {
        font-weight: bold;
        color: $curious_blue;

        > div {
          background-color: $link_water;
        }

        img {
          filter: invert(62%) sepia(11%) saturate(4813%) hue-rotate(159deg)
            brightness(87%) contrast(93%);
        }
      }
    }
  }

  .side-navbar.active {
    width: 240px;

    .header {
      overflow-x: hidden;
      .logo {
        display: inline;
      }
    }

    .links-container {
      a {
        > div {
          span {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xl) {
  .side-navbar-wrapper.active {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;

    .side-navbar.active {
      position: fixed;
      height: 100%;
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .side-navbar-wrapper {
    width: 64px;
    height: 64px;

    .side-navbar {
      box-shadow: 1px 1px 0px $silver;
      .links-container {
        display: none;
      }
    }
  }

  .side-navbar-wrapper.active {
    .side-navbar.active {
      .links-container {
        display: block;
      }
    }
  }
}
