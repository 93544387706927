.recommended-product-container {
  padding: 16px 16px 24px 16px;
  border-bottom: 1px solid $silver;

  .recommended-product-header {
    font-size: 18px;
    text-transform: uppercase;
    color: $cornflower-blue;
    font-weight: bold;
    padding: 18px 0;
  }

  .recommended-product-description {
    color: $cornflower-blue;
    margin-bottom: 16px;
  }

  .recommended-product-card {
    padding: 24px 16px;
    border: 1px solid $silver;
    border-radius: 8px;
    display: flex;
    flex-direction: row;

    .product-image {
      width: 175px;
      height: 175px;
      flex: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .product-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-left: 24px;

      > div:first-child {
        color: $cornflower-blue;
        width: 100%;
        .product-title {
          align-items: flex-start;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
      }

      > div:nth-child(2) {
        width: 30px;
        height: 30px;
        align-self: center;
        margin-left: 24px;
        img {
          width: 100%;
          height: 100%;
          filter: invert(54%) sepia(48%) saturate(0%) hue-rotate(134deg)
            brightness(91%) contrast(87%);
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .recommended-product-container {
    .recommended-product-card {
      flex-direction: column;
      align-items: center;

      .product-image {
        width: 145px;
        height: 145px;
      }

      .product-content {
        margin: 22px 0 0 0;
        text-align: center;
      }
    }
  }
}
