.trait-status-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  text-align: center;

  > div {
    width: 100%;

    .bar-title {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 42px;
      margin-bottom: 8px;
      padding: 0 6px;
      font-size: 14px;
      text-transform: uppercase;
    }

    .range {
      color: $mine_shaft;
      margin-top: 8px;
    }

    .up-arrow {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #fff;
      margin: auto;
    }

    .bar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 24px;
      border-radius: 0 24px 24px 0;
      margin-right: 2px;
      background-color: #00a8e1;

      .bar-splitter {
        width: 12px;
        height: 24px;
        border-radius: 0 24px 24px 0;
        background-color: #fff;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .trait-status-bar {
    > div {
      .bar-title {
        min-height: 64px;
      }
    }
  }
}
