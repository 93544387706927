.heritage-dropdown {
  width: 100%;
  height: 40px;
  border: 1px solid $silver;
  border-radius: 4px;
  color: $dove_gray;
  padding: 0px 13px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  .dropdown {
    display: none;
  }

  .selected {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    >div:last-child {
      margin-left: 8px;
    }
  }
}

.heritage-dropdown.active {
  border: 1px solid $picton_blue;

  .dropdown {
    width: 100%;
    background-color: white;
    border: 1px solid $picton_blue;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    display: block;
    position: absolute;
    top: 52px;
    left: 0px;

    .option {
      padding: 10px 14px;

      &:hover {
        background-color: $polar;
        cursor: pointer;
      }

      &:first-child:hover {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child:hover {
        border-radius: 0px 0px 4px 4px;
      }
    }
  }
}

.heritage-dropdown.error {
  border: 1px solid $thunderbird;
  box-shadow: 0px 0px 5px #cc111166;
}

@media only screen and (max-width: $break-point-sm) {
  .heritage-dropdown {
    .dropdown {
      height: 245px;
      overflow-x: auto;
    }
  }
}
