.dna-report-one-whats-included-wrapper {
  padding: $dna_section_wrapper_padding;
  background: #fff;

  .dna-report-one-whats-included {
    margin: 0 auto;
    max-width: $dna_home_section;

    > div:last-child {
      display: flex;
      flex-direction: row;

      > div:first-child {
        max-width: 58.5%;
        margin-right: 1.5%;
        color: $itovi_dna_astronaut_blue;
        font-size: 20px;
      }

      > div:last-child {
        max-width: 40%;

        > img {
          max-width: 100%;
        }
      }
    }
    .image-stack {
      display: grid;
      grid-template-columns: repeat(18, 1fr);
      position: relative;
      margin: auto;

      .image-stack-item-top {
        grid-column: 6 / -1;
        grid-row: 1;
        padding-top: 75%;
        z-index: 1;
      }

      .image-stack-item-bottom {
        grid-column:  1 / span 13;
        grid-row: 1;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .dna-report-one-whats-included-wrapper {
    .dna-report-one-whats-included {
      > div:first-child {
        max-width: 100%;
      }

      > div:last-child {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin-top: 36px;

        > div:first-child {
          max-width: 100%;
          margin: 0 0 36px;
        }

        > div:last-child {
          max-width: 100%;
        }
      }
    }
  }
}
