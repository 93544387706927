.app-sb {
  width: 450px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  background-color: #212121;

  .toast-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #212121;
    color: #fff;
    font-weight: 400;
    .close {
      color: #fff;
      opacity: 100;
    }
  }

  .toast-body {
    color: #fff;
  }
}

@media only screen and (max-width: 375px) {
  .app-sb {
    width: 97%;
  }
}
