.show-more-card {
  width: 100%;
  padding: 24px 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $silver;
  }

  .show-more-heading {
    font-size: 18px;
    font-weight: bold;
    color: $cornflower-blue;
    margin-bottom: 18px;
  }

  .show-more-container {
    font-size: 15px;
    color: $cornflower-blue;
    margin-bottom: 18px;

    .image-container {
      float: left;

      img {
        width: 176px;
        height: 176px;
        margin-right: 16px;
        margin-bottom: 16px;
        border-radius: 8px;
      }
    }

    .show-more-content {
      p:not(:first-child) {
        display: none;
      }
    }

    .show-more-content.active {
      p {
        display: block;
      }
    }
  }

  .show-more-button {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 9px;

    button {
      font-size: 18px;
      color: $curious_blue;
      background-color: #fff;
      border: none;

      svg {
        margin-left: 10px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .show-more-card {
    .show-more-container {
      .image-container {
        float: left;

        img {
          width: 135px;
          height: 135px;
          margin-right: 16px;
          margin-bottom: 16px;
          border-radius: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xs) {
  .show-more-card {
    padding: 16px 0;
  }
}
