.top-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  background-color: #fff;

  > div:first-child {
    display: flex;
    width: 100%;
    height: 64px;

    .top-bar-brand {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 12px;

      img {
        height: 45px;
      }
    }

    .breadcrumbs {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 16px;
      text-transform: capitalize;

      a {
        color: $dove_gray;
        text-decoration: none;

        &:hover {
          color: $curious_blue;
        }

        &:last-child {
          color: $curious_blue;
        }
      }
    }

    .breadcrumb-dropdown {
      display: flex;
      position: relative;
      display: none;
      height: 100%;
      margin-left: 16px;
      text-transform: capitalize;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }

      > div:first-child {
        display: flex;
        height: 100%;
        padding-top: 5px;
        align-items: center;

        svg {
          margin-left: 4px;
        }
      }

      .dropdown {
        top: 101%;
        z-index: 999;
        left: 0px;
        min-width: 200px;
        padding: 8px;
        display: none;
        position: absolute;
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 1px 3px 1px $silver;

        ul {
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            a {
              display: flex;
              align-items: center;
              width: 100%;
              height: 32px;
              padding: 4px 8px;
              margin-bottom: 4px;
              border-radius: 8px;
              color: $dove_gray;
              text-decoration: none;

              &:hover {
                background-color: $link_water;
              }
            }
          }
        }
      }

      .dropdown.active {
        display: block;
      }
    }

    .auth {
      display: flex;
      position: relative;
      align-items: center;
      height: 100%;
      padding: 16px 12px 16px 16px;
      text-transform: capitalize;
      color: $dove_gray;
      font-weight: 300;

      &:hover {
        cursor: pointer;
        background-color: $seashel;
      }

      &:active,
      &:visited {
        background-color: #fff;
      }

      .avatar {
        margin-right: 8px;
        width: 32px;
        height: 32px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-weight: 500;
      }

      svg {
        margin-left: 6px;
      }

      .dropdown {
        top: 101%;
        z-index: 999;
        right: 0px;
        min-width: 200px;
        padding: 8px;
        display: none;
        position: absolute;
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 1px 3px 1px $silver;

        ul {
          margin: 0px;
          padding: 0px;
          list-style-type: none;

          li {
            a {
              display: flex;
              align-items: center;
              width: 100%;
              height: 32px;
              padding: 4px 8px;
              margin-bottom: 4px;
              border-radius: 8px;
              color: $dove_gray;
              text-decoration: none;

              &:hover {
                background-color: $link_water;
              }
            }
          }
        }
      }

      .dropdown.active {
        display: block;
      }
    }

    .sign-in {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        display: block;
      }
    }
    .login-icon {
      height: 40px;
      width: 40px;
      filter: brightness(0) invert(1);
      margin-right: 30px;
    }

    > div:last-child {
      margin: 0 12px 0 auto;
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .top-bar {
    > div:first-child {
      .breadcrumbs {
        display: none;
      }
      .breadcrumb-dropdown {
        display: block;
      }
    }
  }
  .auth {
    .name {
      display: none;
    }
  }
}

@media only screen and (max-width: 495px) {
  .top-bar {
    > div:first-child {
      .breadcrumb-dropdown {
        max-width: 150px;

        > div:first-child {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .top-bar {
    > div:first-child {
      .breadcrumb-dropdown {
        max-width: 164px;
      }
    }
  }
}
