@import "_variables.scss";
@import "~bootstrap/scss/bootstrap";

//-------Globals-------//
@import "./index.scss";
@import "./buttons.scss";
@import "./forms.scss";
@import "./containers.scss";
@import "./modal.scss";

//-------Layout---------//
@import "./components/layout/topNavbar.scss";
@import "./components/layout/sideNavbar.scss";
@import "./components/layout/footer.scss";
@import "./components/layout/rightSlideOut.scss";
@import "./components/layout/homePageNav.scss";

//-------Home Page----------//
@import "./pages/dnaDashboard.scss";
@import "./pages/home.scss";
@import "./components/dnaHome/productCard.scss";
@import "./components/dnaHome/reportCard.scss";
@import "./components/dnaHome/dnaBanner.scss";
@import "./components/dnaHome/howItWorks.scss";
@import "./components/dnaHome/matchWellnessProducts.scss";
@import "./components/dnaHome/ourScience.scss";
@import "./components/dnaHome/privacy.scss";
@import "./components/dnaHome/allTraitsCard.scss";
@import "./components/dnaHome/redeemModal.scss";
@import "./components/dnaHome/heritageModal.scss";
@import "./components/dnaHome/redeemCodeHelper.scss";
@import "./components/dnaHome/dnaGsr.scss";
@import "./components/dnaHome/dnaWhatsInReport.scss";
@import "./components/dnaHome/reportOneWhatsIncluded.scss";
@import "./components/dnaHome/frequentlyAskedQuestions";
@import "./components/dnaHome/congratsModal.scss";

//-------Report Page----------//
@import "./pages/report.scss";
@import "./components/report/summaryCard.scss";
@import "./components/report/traitCard.scss";
@import "./components/report/reportSummary.scss";

//-------About Report Page----------//
@import "./pages/aboutReport.scss";

//-------Trait Page----------//
@import "./pages/trait.scss";
@import "./components/trait/traitNav.scss";
@import "./components/trait/foodSummary.scss";
@import "./components/trait/collapsableCard.scss";
@import "./components/trait/nutrientSummary.scss";
@import "./components/trait/traitSummary.scss";
@import "./components/trait/traitStatusBar.scss";
@import "./pages/section.scss";

//-------All Traits Page----------//
@import "./pages/AllTraits.scss";

//-------Components-------//
@import "./components/kitTracker/singleKitTracker.scss";
@import "./components/loading.scss";
@import "./components/loadingDots.scss";
@import "./components/barChart.scss";
@import "./components/showMoreCard.scss";
@import "./components/recommendedProductCard.scss";
@import "./components/notificationBanner.scss";
@import "./components/customerSupportModal.scss";
@import "./components/heritageDropdown.scss";
@import "./components/datePicker.scss";

//--------Kit Registration----------//
@import "./components/kitRegistration/kitPreScan.scss";
@import "./components/kitRegistration/kitActivationQuestions.scss";
@import "./components/kitRegistration/reviewKitActivation.scss";
@import "./components/kitRegistration/swabInstructions.scss";
@import "./components/kitRegistration/termsOfService.scss";
@import "./pages/kitRegistration.scss";

//--------Open app toast--------//
@import "./components/openApp.scss";

//----------Auth--------//
@import "./pages/auth/login.scss";
@import "./pages/auth/register.scss";

//--------Legal--------//
@import "./pages/legal/termsandconditions.scss";

//-------Not Found Page------//
@import "./pages/notFound.scss";

//---------My Account--------//
@import "./pages/myAccount.scss";

//---------Master Traits--------//
@import "./admin/pages/masterTraits.scss";
@import "./admin/components/masterTraits/masterTraitForm.scss";
@import "./admin/components/masterTraits/masterTraitNav.scss";
@import "./admin/components/masterTraits/masterTraitScores.scss";
@import "./admin/pages/publishMasterTraits.scss";

//--------Form Components-------//
@import "./components/forms/selectDropdown.scss";
