.content-container {
  border-radius: 8px;
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}

.xl-container {
  margin: 24px auto;
  max-width: 1800px;
  padding: 0 16px;
}

.lg-container {
  margin: 24px auto;
  max-width: 1600px;
  padding: 0 16px;
}

.md-container {
  margin: 24px auto;
  max-width: 1400px;
  padding: 0 16px;
}

.sm-container {
  margin: 24px auto;
  max-width: 800px;
  padding: 0 16px;
}

//Grid Contianers

.grid-half-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 24px;
}

//Flex Containers

.flex-half-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  > div:first-child {
    margin-right: 12px;
  }
  > div:last-child {
    margin-left: 12px;
  }
  > div {
    width: 100%;
  }
}

.flex-two-thirds-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  > div:first-child {
    width: 33%;
    margin-right: 12px;
  }
  > div:last-child {
    width: 67%;
    margin-left: 12px;
  }
}

@media only screen and (max-width: $break-point-lg) {
  .content-container {
    border-radius: 0px;
  }

  .xl-container,
  .lg-container,
  .md-container {
    padding: 0;
  }

  //Grid Contianers
  .grid-half-container {
    grid-template-columns: auto;
  }

  //Flex Containers
  .flex-half-container,
  .flex-two-thirds-container {
    flex-flow: column;

    > div:first-child {
      width: 100%;
      margin: 0 0 24px 0;
    }

    > div:last-child {
      width: 100%;
      margin: 0 0 24px 0;
    }
  }
}
