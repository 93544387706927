.master-traits {
  input {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid $silver;
    text-indent: 6px;
    background-color: #ffff;
  }

  select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid $silver;
    background-color: #ffff;
  }

  textarea {
    width: 100%;
    height: 250px;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid $silver;
    background-color: #ffff;
  }

  input,
  textarea {
    font-size: 14px;
    &:focus {
      outline: none !important;
      border-color: $primary;
      box-shadow: 0 0 2px $primary;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .master-traits {
    .button {
      padding: 2 6px;
      font-size: 14px;
    }
  }
}
