.trait-card {
  .trait-title {
    padding: 16px;
    text-transform: capitalize;
    font-size: 18px;
    border-bottom: 1px solid $pigeon_post;
    color: $nile_blue;
  }

  .summary-card {
    padding: 16px 16px 0 16px;
    position: relative;

    > div {
      position: relative;

      img {
        border-radius: 8px;
      }

      > div {
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        > div:first-child {
          width: 100%;
          line-height: 30px;
          font-weight: bold;
          font-size: 28px;
          padding-left: 24px;
        }

        > div:last-child {
          width: 40px;

          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
              brightness(103%) contrast(103%);
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .section-description {
    padding: 18px 24px;
    border-bottom: 1px solid $pigeon_post;
  }

  .trait-container {
    padding: 16px;

    .trait {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid $pigeon_post;

      &:hover {
        background-color: $seashel;
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }

      .name-status {
        display: flex;
        flex-direction: row;
        padding-left: 16px;
        flex-grow: 1;
        color: $mine_shaft;
        font-weight: 500;
        width: 200px;

        > div {
          width: 100%;
        }

        > div:last-child {
          margin-left: 10%;
        }
      }

      .image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $gray;
        flex: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 70% 0;
          border-radius: 50%;
        }
      }

      .icon {
        padding-left: 10px;
        img {
          width: 20px;
          height: 20px;
          filter: invert(54%) sepia(48%) saturate(0%) hue-rotate(134deg)
            brightness(91%) contrast(87%);
        }
      }
    }

    .trait-disabled {
      &:hover {
        background-color: #fff;
        cursor: default;
      }
    }
  }
}

@media only screen and (max-width: $break-point-xl) {
  .trait-card {
    .trait-container {
      .trait {
        .name-status {
          flex-direction: column;

          > div:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .trait-card {
    .trait-container {
      .trait {
        .name-status {
          flex-direction: row;

          > div:last-child {
            margin-left: 10%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .trait-card {
    .trait-container {
      .trait {
        .name-status {
          flex-direction: column;

          > div:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
