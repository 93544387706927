.all-traits-container {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto auto auto;

  .trait-container {
    width: 100%;
    border-radius: 12px;

    &:hover {
      cursor: pointer;
    }

    .trait-heading {
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 12px 12px 0 0;
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px 12px 0 0;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        padding: 12px 24px;

        .name {
          margin-right: 30px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 26px;
          color: #fff;
        }

        .icon {
          img {
            width: 40px;
            height: 40px;
            filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
              brightness(102%) contrast(101%);
          }
        }
      }
    }

    .trait-status {
      padding: 8px 0px;
      text-align: center;
      border-radius: 0 0 12px 12px;
      color: #fff;
      background-color: $dove_gray;
    }
  }
}

@media only screen and (max-width: $break-point-xl) {
  .all-traits-container {
    .trait-container {
      .trait-heading {
        > div:nth-child(2) {
          .name {
            line-height: 26px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .all-traits-container {
    grid-template-columns: auto auto;
    padding: 0px 24px;
  }
}

@media only screen and (max-width: 798px) {
  .all-traits-container {
    padding: 0px 8px;
    grid-template-columns: auto;
    .trait-container {
      .trait-heading {
        > div:nth-child(2) {
          .name {
            line-height: 34px;
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 489px) {
  .all-traits-container {
    .trait-container {
      .trait-heading {
        > div:nth-child(2) {
          .name {
            line-height: 26px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .all-traits-container {
    .trait-container {
      .trait-heading {
        > div:nth-child(2) {
          .name {
            line-height: 22px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
