.summary-card {
  width: 100%;

  img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }

  .summary-content {
    padding: 16px;

    .content-title {
      margin-bottom: 14px;
      color: $gun_powder;
      font-weight: bold;
    }

    p {
      font-size: 15px;
      color: $nile_blue;
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .summary-card {
    img {
      border-radius: 0;
    }
  }
}
