.form-input-group {
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    input {
      width: 100%;
      height: 40px;
      border: 1px solid $silver;
      border-radius: 4px;
      color: #666666;
      padding: 0px 13px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:focus {
        outline: none;
        border: 1px solid picton_blue;
      }
    }
  }

  .input-container.error {
    input {
      border: 1px solid $thunderbird;
      box-shadow: 0px 0px 5px #cc111166;
    }

    .invalid-error {
      margin-top: 6px;
      color: $thunderbird;
    }
  }
}
