.collapsable-card {
  border-radius: 8px;
  margin: 0 24px;

  &:not(:first-child) {
    margin-top: 30px;
  }

  .collapsable-card-heading {
    border-radius: 8px 8px 0 0;
    height: auto;
    width: 100%;
    position: relative;

    .image-filler {
      width: 100%;
      height: 150px;
      background-color: $dove_gray;
      border-radius: 8px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
    .header-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #fff;
      padding: 16px;
      span {
        font-size: 28px;
        font-weight: bold;
        margin-left: 16px;
      }
      svg {
        font-size: 30px;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  .collapsable-card-heading.active {
    .image-filler {
      border-radius: 8px 8px 0 0;
    }
    img {
      border-radius: 8px 8px 0 0;
    }
  }

  .collapsable-card-content {
    border: 1px solid $silver;
    border-top: none;
    display: none;
    border-radius: 0 0 8px 8px;

    .desc {
      padding: 16px 24px;
      color: $cornflower-blue;
    }
    .table-chart {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      > div {
        border-bottom: 1px solid $silver;
        padding-bottom: 16px;
        width: 100%;
        .table-heading {
          padding: 8px 4px;
          color: $cornflower-blue;
          background-color: $seashel;
          text-align: center;
          margin-bottom: 16px;
          text-transform: uppercase;
        }
        .table-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            display: block;
            color: $cornflower-blue;
            &:nth-child(2) {
              font-size: 13px;
            }
          }
        }
      }
    }

    .food-recommendations {
      width: 100%;

      .food-recommendations-header {
        padding: 16px;
        border-bottom: 1px solid $silver;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: $seashel;
          cursor: pointer;
        }

        > div:first-child {
          font-size: 18px;
          font-weight: bold;
          color: $cornflower-blue;
        }

        > div:nth-child(2) {
          color: $dove_gray;
          padding-right: 24px;
        }
      }

      .food-recommendations-table {
        display: none;
        padding: 16px 0;
        color: $cornflower-blue;
        background-color: $white_ice;
        border-bottom: 1px solid $silver;

        > div {
          display: flex;
          flex-direction: row;
          padding: 8px 24px;

          > div {
            width: 100%;
            display: flex;
            align-items: center;

            &:first-child {
              margin-right: 40px;
            }

            &:last-child {
              justify-content: flex-end;
              text-align: right;
              word-break: keep-all;
            }

            span:first-child {
              font-weight: bold;
              margin-right: 4px;
              &::first-letter {
                text-transform: capitalize;
              }
            }
          }
        }
      }

      .food-recommendations-table.active {
        display: block;
      }

      &:last-child {
        .food-recommendations-table {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
  .collapsable-card-content.active {
    display: block;
  }
}

@media only screen and (max-width: $break-point-sm) {
  .collapsable-card {
    margin: 0;
    border-radius: 0;

    .collapsable-card-heading {
      border-radius: 0px;

      .image-filler {
        border-radius: 0px;
        height: 100px;
      }
      img {
        border-radius: 0px;
      }
    }

    .collapsable-card-heading.active {
      .image-filler {
        border-radius: 0;
      }
      img {
        border-radius: 0;
      }
    }

    .collapsable-card-content {
      border-radius: 0px;

      .preferred-protein {
        .preferred-protein-table {
          border-radius: 0px;
        }
      }

      .food-recommendations {
        .food-recommendations-header {
          > div:first-child {
            font-size: 15px;
          }
        }

        .food-recommendations-table {
          > div {
            padding: 8px 16px;

            > div {
              &:first-child {
                font-size: 13px;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
