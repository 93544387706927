.kit-activation {
  max-width: 550px;
  margin: auto;

  h2 {
    text-align: center;
  }

  .form-data {
    margin: auto;
    padding: 20px 12px 10px 12px;
    font-size: 20px;

    label {
      font-weight: bold;
    }

    ul {
      padding: 0px;
      list-style-type: none;

      li {
        margin-bottom: 8px;
      }
    }
  }
}
