.not-found {
  padding: 24px;

  .not-found-header {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;

    > div:first-child {
      font-size: 82px;
      font-weight: bold;
      line-height: 1;
      //   color: $dove_gray;
      color: $cornflower-blue;
    }

    > div:last-child {
      margin-top: 12px;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: $emperor;
    }
  }

  .not-found-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;

    > div {
      flex: none;
      margin: 0 6px;

      .button {
        display: block;
      }
    }
  }
}
