.support-modal-wrapper {
  z-index: 99;
  overflow-x: auto;

  .support-modal {
    margin-top: 167px;
    margin-bottom: 24px;
    width: 290px;
    height: 230px;
    background: #fff;
    box-shadow: 0px 0.5px 3px #00000066;
    border-radius: 9px;
    padding: 18px 23px 23px 20px;

    .support-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 13px;
      .header-title {
        text-transform: capitalize;
        font-weight: bold;
        font-size: 20px;
        margin-right: 12px;
        color: $eden;
      }

      .dismiss {
        width: 16px;
        height: 16px;
        background: url("../../assets/close_icon.svg");
        &:hover {
          background: url("../../assets/close_icon_hover.svg");
          cursor: pointer;
        }
      }
    }

    .support-description {
      font-size: 12px;
      margin-bottom: 23px;
      color: $dove_gray;
    }

    .contact-list {
      .contact-item {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .contact-icon {
          margin-right: 20px;
          height:33px;
          width:33px;
        }

        .contact-content {
          .contact-title {
            font-size: 14px;
            color: $eden;
          }
          .contact-source {
            font-size: 12px;
            color: $dove_gray;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xs) {
  .support-modal-wrapper {
    .support-modal {
      width: 100%;
      padding: 24px 12px;
      border-radius: 0;
    }
  }
}
