.loading-dots {
  margin: auto;
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    margin: 0 2px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .dot-1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .dot-2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.loading-dots.xs {
  width: 30px;

  > div {
    width: 4px;
    height: 4px;
  }
}

.loading-dots.small {
  width: 50px;
  > div {
    width: 12px;
    height: 12px;
  }
}

.loading-dots.medium {
  width: 50px;
  > div {
    width: 12px;
    height: 12px;
  }
}

.loading-dots.white {
  > div {
    background-color: #fff;
  }
}

.loading-dots.dove_gray {
  > div {
    background-color: $dove_gray;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
