.account-registration {
  margin: auto;
  max-width: 550px;
  padding: 24px;

  .title {
    text-align: center;
    h1 {
      margin-bottom: 0px;
      font-weight: 600;
    }
    p {
      font-size: 26px;
      font-weight: 300;
    }
  }

  .sign-in {
    text-align: center;

    a {
      color: $itovi_dna_royal_purple;
      text-decoration: none;
    }
  }
  input[type="checkbox"] {
    transform: translateY(50%);
  }
  .checkbox {
    & label {
      transform: translateY(50%);
    }
  }
}

.need-a-kit {
  text-align: center;
}
