.kit-activation-questions {
  margin: auto;
  max-width: 1200px;
  .activation-questions {
    min-width: 570px;
    .row {
      align-items: center;
      justify-content: center;
    }
    label {
      font-size: 26px;
      color: $itovi_dna_midnight_blue;
      text-align: right;
      margin: 0;
    }
    .form-check {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .kit-activation-questions {
    .info-container {
      display: block;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }

    .activation-questions {
      margin: auto;
      label {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .kit-activation-questions {
    .info-container {
      max-width: 570px;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .kit-activation-questions {
    .activation-questions {
      min-width: 320px;
    }
    .col {
      padding-left: 0px;
      padding-right: 0px;
    }
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
    .info-container {
      min-width: 320px;
      text-align: center;
    }
  }
  .pregnant {
    text-align: center !important;
  }
}
