.product-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #013d5b33;
  border: 1px solid #013d5b;
  background-color: #f5fdfd;

  .product-name {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 78px;
    font-size: 24px;
    font-weight: bold;
    color: $cornflower-blue;
    text-align: center;
  }

  .product-image {
    width: 90%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  > div:last-child {
    width: 100%;
    margin: 12px auto 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    .buy-now {
      margin-top: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .product-price {
        margin-top: 8px;
        font-weight: bold;
        font-size: 45px;
        color: $itovi_dna_bright_green;
        text-align: center;
        margin-bottom: 10px;

        .monthly-price {
          font-size: 21px;
          color: $itovi_dna_light_blue_gray;
          font-weight: 300;

          sup {
            font-size: 12px;
          }
        }
      }

      button {
        width: 100%;
        height: 60px;
        display: block;
        font-size: 28px;
        font-weight: bold;
        color: #fff;
        background-color: $itovi_dna_bright_green;
        border: none;
        border-radius: 2px;
        outline: none;

        &:hover {
          background-color: $itovi_dna_mint_green;
          border: none;
        }

        &:active,
        &:focus {
          background-color: $itovi_dna_mint_green;
          border: none;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .product-card {
    > div:last-child {
      .product-name {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 449px) {
  .product-card {
    border-radius: 0;

    > div:last-child {
      width: 100%;

      .buy-now {
        .product-price {
          font-size: 42px;
        }

        button {
          font-size: 22px;
        }
      }
    }
  }
}
