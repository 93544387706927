.login-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 550px;
  padding: 24px;

  h2 {
    text-align: center;
    margin-bottom: 0px;
    font-weight: 600;
  }
  p {
    font-size: 26px;
    font-weight: 300;
    text-align: center;
  }

  .sign-in {
    text-align: center;

    a {
      color: #a23a95;
      text-decoration: none;
    }
  }
}
