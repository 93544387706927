.about-report {
  max-width: 1200px;
  margin: 24px auto;
  border-radius: 8px;
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;

  .report-header {
    position: relative;
    width: 100%;

    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding: 24px;
      font-size: 40px;
      font-weight: bold;
    }
  }

  .description {
    padding: 26px 26px 0 26px;

    > div:first-child {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .included-traits {
    padding: 26px;

    > div:first-child {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: bold;
    }

    ul {
      text-transform: capitalize;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .about-report {
    border-radius: 0px;

    .report-header {
      img {
        border-radius: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .about-report {
    border-radius: 0px;

    .report-header {
      > div {
        font-size: 6vw;
      }
    }
  }
}
