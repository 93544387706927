.master-trait-scores {
  .remove {
    margin-top: 24px;
  }

  .score-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $silver;
    padding-bottom: 12px;
    min-height: 60px;
    margin: 0 16px 12px 16px;
    text-align: center;

    .score-heading-title {
      font-size: 16px;
      font-weight: bold;
      color: $cornflower-blue;
    }
  }

  .success-strategy {
    border-radius: 8px;
    border: 1px solid $silver;
    margin: 0 16px;
    &:not(:first-child) {
      margin-top: 16px;
    }

    &:hover {
      cursor: pointer;
    }
    &:active {
      cursor: grabbing;
    }

    .strategy-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;

      font-weight: bold;
    }

    :last-child {
      svg:last-child {
        margin-left: 8px;
      }
    }

    > div:last-child {
      display: none;
    }
  }

  .success-strategy.active {
    &:active {
      cursor: pointer;
    }
    > div:last-child {
      display: block;
    }
  }

  .score-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;

    > div:first-child {
      margin-right: 8px;
    }
  }
}
