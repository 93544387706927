.kit-pre-scan-form {
  margin: auto;
  max-width: 550px;
  h2 {
    margin-bottom: 20px;
  }
  .title {
    text-align: center;

    h1 {
      font-weight: 600;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 100%;
    }
  }

  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}
