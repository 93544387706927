.dna-gsr-wrapper {
  background-color: #fff;
  padding: $dna_section_wrapper_padding;

  .dna-gsr {
    max-width: $dna_home_section;
    margin: auto;

    .inline-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .dna-gsr-content {
        width: 55%;
        margin-right: 5%;

        > div {
          margin-bottom: 25px;

          .title {
            font-weight: bold;
            color: $astronaut_blue;
            font-size: 24px;
          }

          .description {
            color: $astronaut_blue;
            font-size: 20px;
          }
        }
      }

      .dna-gsr-image {
        width: 40%;
        position: relative;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xl) {
  .dna-gsr-wrapper {
    width: 100%;

    .dna-gsr {
      .inline-container {
        flex-direction: column;
        align-items: center;

        .dna-gsr-content {
          width: 100%;
          margin-right: 0;
        }

        .dna-gsr-image {
          width: 100%;
          max-width: 700px;
        }
      }
    }
  }
}
