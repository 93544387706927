//  BOOTSTRAP OVERRIDES
$success: #7cc24d;
$info: #013d5b;
$warning: #f37f89;
$primary: #19a5d8;
$secondary: #666666;

// iTOVi colors
$light-primary: #00c0ef;
$brown: #707070;
$light-grey: #cdd4d7;
$grey: #9ba8ae;
$cream: #e0e0e0;

$itovi_dna_froly: #f37f89;
$itovi_dna_silver_chalice: #aaaaaa;
$itovi_dna_astronaut_blue: #013d5b;
$itovi_dna_bright_green: #7cc24d;
$itovi_dna_royal_purple: #a23a95;
$itovi_dna_bright_blue: #0093c8;
$itovi_dna_midnight_blue: #243746;
$itovi_dna_mint_green: #58c1a6;
$itovi_dna_dark_blue_gray: #5b6771;
$itovi_dna_light_blue_gray: #758591;
$itovi_dna_light_warm_gray: #dae1e9;
$itovi_form_background: #f0f0f0;

//New Colors
$iron: #e2e3e4;
$silver: #cccccc;
$medium_silver: #bbbbbb;
$seashel: #f1f1f1;
$dove_gray: #666666;
$emperor: #525252;
$link_water: #e7eff7;
$curious_blue: #1aa6da;
$christi: #66bb11;
$forest_green: #1c9b1f;
$nile_blue: #172b4d;
$cornflower-blue: #213050;
$attol: #0d5183;
$pigeon_post: #aec3d9;
$gun_powder: #43425d;
$gray: #888888;
$mine_shaft: #333333;
$caribbean_green: #06c4a5;
$white_ice: #daf0f9;
$rouge: #a23a95;
$vivid_violet: #8f34af;
$san_marino: #466ab8;
$pacific_blue: #0996c0;
$cerulean: #00a8e1;
$endeavour: #0253a1;
$astronaut_blue: #013d5b;
$bright_red: #aa0000;
$mystic: #ecf0f4;
$thunderbird: #cc1111;
$fuchsia_blue: #7e57c2;
$scorpion: #5f5f5f;
$eden: #0e4457;
$eastern_blue: #128ab4;
$picton_blue: #3DB5E6;
$polar: #E7F4FA;

//Screen break points
$break-point-xs: 335px;
$break-point-sm: 576px;
$break-point-md: 767px;
$break-point-lg: 959px;
$break-point-xl: 1279px;

//Container Widths and paddings
$dna_home_section: 1400px;
$dna_section_wrapper_padding: 40px 24px;
