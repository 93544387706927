.right-slide-out-wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: -400px;
  z-index: 99;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .right-slide-out {
    position: absolute;
    top: 0;
    right: -400px;
    height: 100%;
    width: 400px;
    background-color: #fff;
    transition: all 0.35s ease-in-out;
  }
}

.right-slide-out-wrapper.active {
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  .right-slide-out {
    right: 0;
  }
}

.right-slide-out-wrapper.deactive {
  transition-delay: 1s;
}
