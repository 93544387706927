.heritage-modal {
  position: absolute;
  top: 141px;
  display: flex;
  flex-direction: column;
  width: 472px;
  padding: 24px;
  background: #fff;
  border-radius: 9px;
  box-shadow: 1px 1px 6px #0000004d;

  .heritage-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;

    .header-title {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 22px;
      margin-right: 12px;
      color: $eden;
    }

    .dismiss {
      width: 16px;
      height: 16px;
      background: url("../../../assets/close_icon.svg");
      background-repeat: no-repeat;
      &:hover {
        background: url("../../../assets/close_icon_hover.svg");
        cursor: pointer;
      }
    }
  }

  .input-container {
    margin-bottom: 25px;
    margin-right: 20px;

    .input-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      font-size: 12px;
      color: $dove_gray;
      > div:first-child {
        margin-right: 12px;
      }
    }
  }

  .error-message {
    margin-top: 12px;
    font-size: 13px;
    color: $thunderbird;
  }

  .heritage-description {
    margin: 0 16px 25px 3px;
    font-size: 13px;
    color: $dove_gray;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
}

@media only screen and (max-width: $break-point-sm) {
  .heritage-modal {
    width: 100%;
    border-radius: 0;
    top: 30px;

    .heritage-modal-header {
      .header-title {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-xs) {
  .heritage-modal {
    padding: 24px 12px;
  }
}
