.swabInstructions {
  max-width: 1016px;
  margin: auto;
  text-align: center;
  color: $itovi_dna_astronaut_blue;
  .swabContent {
    padding: 20px 128px;
    h2 {
      font-size: 32px;
      font-weight: bold;
    }

    .card {
      background-color: inherit;
      border: none;

      .card-title {
        margin-bottom: 5px;
        font-weight: bold;
      }
      img {
        width: 50px;
        height: 50px;
        margin: auto;
        color: $itovi_dna_royal_purple;
      }
    }

    .swabInfo {
      font-size: 18px;
    }

    .swabVideo {
      margin: 20px auto;
      height: 280;
      max-width: 500px;
    }

    .swabSteps {
      .col {
        max-width: 30px;
      }

      button {
        min-width: 250px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .swabInstructions {
    width: 100%;
    .swabContent {
      padding: 20px 14px;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .swabInstructions {
    .swabContent {
      .swabSteps {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .swabInstructions {
    .swabContent {
      .swabSteps {
        button {
          width: 100%;
        }
      }
    }
  }
}
