.loading-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loading {
    width: 125px;
    img {
      width: 100%;
      animation: rotation 3s infinite linear;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
