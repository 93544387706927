.frequently-asked-questions-wrapper {
  padding: $dna_section_wrapper_padding;
  background: $itovi_dna_light_blue_gray;
  color: #fff;

  .frequently-asked-questions {
    margin: auto;
    max-width: $dna_home_section;

    .dna-faq-heading {
      font-size: 50px;
      width: 100%;
      margin-bottom: 32px;
      border-bottom: 2px solid #fff;
    }

    .faq-content {
      font-size: 20px;
      display: flex;
      flex-direction: row;

      > div {
        width: 50%;
      }

      > div:first-child {
        margin-right: 2%;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .frequently-asked-questions-wrapper {
    .frequently-asked-questions {
      .faq-content {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin-top: 36px;

        > div {
          width: 100%;
        }

        > div:first-child {
          margin: 0;
        }
      }
    }
  }
}
