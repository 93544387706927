.button {
  border-radius: 8px;
  border: none;
  padding: 0 16px;
  font-size: 15px;
  height: 39px;
  min-width: 130px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    font-weight: 600;
  }
}
.button-xs {
  min-width: 45px;
}
.button-sm {
  min-width: 90px;
}
.button-lg {
  min-width: 180px;
}

a.button:hover {
  text-decoration: none;
}

.button-success {
  background-color: $christi;
  color: #fff;

  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg)
      brightness(100%) contrast(99%);
  }

  &:hover {
    background-color: lighten($christi, 15);
  }
}

.button-outline-success {
  border: 1px solid $christi;
  color: $christi;
  background-color: #fff;

  img {
    filter: invert(76%) sepia(70%) saturate(3295%) hue-rotate(46deg)
      brightness(92%) contrast(87%);
  }

  &:hover {
    background-color: $christi;
    color: #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
        brightness(102%) contrast(101%);
    }
  }
}

.button-outline-success.disabled {
  &:hover {
    background-color: #fff;
    color: $christi;
    cursor: default;

    img {
      filter: none;
    }
  }
}

.button-primary {
  background-color: $primary;
  color: #fff;
  &:focus,
  &:active {
    background: $primary;
  }
  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg)
      brightness(100%) contrast(99%);
  }

  &:hover {
    background-color: #2693bf;
  }
}

.button-outline-primary {
  border: 1px solid $primary;
  color: $primary;
  background-color: #fff;

  img {
    filter: invert(49%) sepia(98%) saturate(953%) hue-rotate(158deg)
      brightness(94%) contrast(81%);
  }

  &:hover {
    background-color: $primary;
    color: #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
        brightness(102%) contrast(101%);
    }
  }
}

.button-danger {
  background-color: $bright_red;
  color: #fff;

  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg)
      brightness(100%) contrast(99%);
  }

  &:hover {
    background-color: lighten($bright_red, 4);
  }
}

.button-outline-danger {
  border: 1px solid $bright_red;
  color: $bright_red;
  background-color: #fff;

  img {
    filter: invert(9%) sepia(68%) saturate(4968%) hue-rotate(354deg)
      brightness(118%) contrast(115%);
  }

  &:hover {
    background-color: $bright_red;
    color: #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
        brightness(102%) contrast(101%);
    }
  }
}

.button-secondary {
  background-color: $dove_gray;
  color: #000;

  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg)
      brightness(100%) contrast(99%);
  }

  &:hover {
    background-color: lighten($dove_gray, 4);
  }
}

.button-outline-secondary {
  border: 1px solid $medium_silver;
  color: $dove_gray;
  background-color: #fff;

  img {
    filter: invert(9%) sepia(68%) saturate(4968%) hue-rotate(354deg)
      brightness(118%) contrast(115%);
  }

  &:hover {
    background-color: $medium_silver;
    color: #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
        brightness(102%) contrast(101%);
    }
  }
}

.button-disabled {
  background-color: $emperor;
  color: #fff;

  img {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(66deg)
      brightness(100%) contrast(99%);
  }

  &:hover {
    background-color: lighten($emperor, 4);
  }
}

.button-outline-disabled {
  border: 1px solid $emperor;
  color: $emperor;
  background-color: #fff;

  img {
    filter: invert(32%) sepia(0%) saturate(0%) hue-rotate(204deg)
      brightness(94%) contrast(90%);
  }

  &:hover {
    background-color: $emperor;
    color: #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(4070%) hue-rotate(14deg)
        brightness(102%) contrast(101%);
    }
  }
}

a.button-primary:hover,
a.button-success:hover,
a.button-danger:hover,
a.button-disabled:hover {
  color: #fff;
}

.button-outline-success,
.button-outline-primary,
.button-outline-danger,
.button-outline-disabled {
  padding: 0px 16px;
}
