.dna-dashboard {
  .dna-report-section {
    .dna-report-title {
      font-weight: bold;
      color: $cornflower-blue;
      font-size: 32px;
      padding-bottom: 24px;
    }

    .dna-report-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
    }
  }
}

@media only screen and (max-width: 1159px) {
  .dna-dashboard {
    .dna-report-section {
      padding: 0 24px;
      .dna-report-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .dna-dashboard {
    .dna-report-section {
      padding: 0 24px;
      .dna-report-list {
        width: 75%;
        margin: auto;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .dna-dashboard {
    .dna-report-section {
      padding: 0 24px;
      .dna-report-list {
        width: 90%;
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .dna-dashboard {
    .dna-report-section {
      padding: 0;
      .dna-report-title {
        font-size: 30px;
      }
      .dna-report-list {
        width: 100%;
        margin: auto;
      }
    }
  }
}
