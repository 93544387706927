.home-page {
  .shop-list-container {
    padding: 24px;
    background-color: #fff;

    > div {
      max-width: $dna_home_section;
      margin: 0 auto 20px auto;

      .shop-list {
        display: grid;
        grid-gap: 18px;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 20px;
      }
    }
  }

  .dna-section-heading {
    margin-bottom: 25px;
    padding-bottom: 10px;
    font-size: 42px;
    font-weight: bold;
    width: 100%;
    color: $rouge;
    border-bottom: 2px solid $itovi_dna_midnight_blue;
  }
}

@media only screen and (max-width: 1024px) {
  .home-page {
    .shop-list-container {
      padding: 24px 14px;

      > div {
        .shop-list {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .home-page {
    .shop-list-container {
      padding: 24px 8px;

      > div {
        .title {
          font-size: 30px;
        }

        .shop-list {
          width: 70%;
          margin: auto;
          grid-gap: 24px;
          grid-template-columns: 1fr;
        }
      }
    }

    .dna-section-heading {
      font-size: 36px;
      line-height: 44px;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .home-page {
    .shop-list-container {
      > div {
        .shop-list {
          width: 85%;
        }
      }
    }
  }
}

@media only screen and (max-width: 449px) {
  .home-page {
    .shop-list-container {
      padding: 24px 14px;
      > div {
        .shop-list {
          width: 100%;
        }
      }
    }
  }
}
