.food-summary {
  padding-bottom: 16px;

  .heading {
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px 8px 0 0;
    }

    > div:nth-child(2) {
      display: flex;
      border-radius: 8px 8px 0px 0px;
      background-color: rgba(0, 0, 0, 0.2);
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      font-size: 32px;
    }
  }

  .subheading {
    border-bottom: 1px solid $silver;

    .title {
      padding: 24px 24px 8px 24px;
      font-size: 22px;
      color: $cornflower-blue;
      font-weight: bold;
    }

    .description {
      padding: 0 24px 24px 24px;
      color: $cornflower-blue;
    }
  }

  .macros {
    padding: 24px;
    border-bottom: 1px solid $silver;

    .title {
      color: $cornflower-blue;
      margin-bottom: 16px;

      span {
        &:first-child {
          color: $cornflower-blue;
        }
        &:last-child {
          color: $gray;
        }
      }
    }
  }

  .weight-management {
    .weight-navigation {
      display: flex;
      flex-direction: row;
      padding: 0px 16px;
      margin-bottom: 16px;
      text-transform: uppercase;
      color: $dove_gray;
      border-bottom: 1px solid $silver;

      > div {
        padding: 16px;
        &:hover {
          cursor: pointer;
        }
      }

      > div.active {
        color: $mine_shaft;
        font-weight: bold;
        border-bottom: 2px solid $curious_blue;
      }
    }

    .weight-loss-heading {
      .calories {
        text-align: center;
        font-size: 52px;
        color: $caribbean_green;
        font-weight: bold;
        padding: 8px;
      }

      .title {
        color: $cornflower-blue;
        font-weight: bold;
        text-align: center;
        padding-bottom: 8px;
      }

      .edit-info {
        width: 100%;
        margin: 20px 0 40px 0;

        > p {
          margin-bottom: 14px;
          text-align: center;
        }

        button {
          display: block;
          margin: auto;
        }
      }

      .weight-content {
        color: $cornflower-blue;
        padding: 0px 24px 8px 24px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .food-summary {
    .heading {
      > div:nth-child(2) {
        border-radius: 0;
      }
      img {
        border-radius: 0;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .food-summary {
    .macros {
      padding: 8px;
    }
  }
}
