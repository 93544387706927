.kit-terms-of-service {
  margin: auto;
  max-width: 550px;

  h2 {
    text-align: center;
  }

  ul {
    margin: 20px 0;
    padding: 0;
    list-style-type: none;

    li {
      font-weight: 600;
      margin-bottom: 15px;
    }
  }

  .agreeance {
    font-size: 15px;
  }

  .form-check {
    padding: 0;
    margin-top: 20px;
  }
}
