.trait-nav {
  .content-container {
    padding: 8px;

    .trait {
      &:not(:first-child) {
        margin-bottom: 8px;
        border-top: 1px solid $silver;
        margin-top: 8px;

        > div {
          margin-top: 8px;
        }
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;

        &:hover {
          background-color: $seashel;
          cursor: pointer;
        }

        .name-status {
          padding-left: 16px;
          flex-grow: 1;
          color: $mine_shaft;
          font-weight: 500;
          text-transform: capitalize;
        }

        .image {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $gray;
          flex: none;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 70% 0;
            border-radius: 50%;
          }
        }

        .icon {
          padding-left: 10px;

          img {
            width: 20px;
            height: 20px;
            filter: invert(54%) sepia(48%) saturate(0%) hue-rotate(134deg)
              brightness(91%) contrast(87%);
          }
        }
      }
    }

    .trait.active {
      > div {
        background-color: $seashel;
      }
    }

    .trait-disabled {
      > div {
        &:hover {
          background-color: #fff;
          cursor: default;
        }
      }
    }
  }
}
