.mobile-home-nav {
  display: none;
}
.dna-home-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 325px;

  &:hover {
    cursor: pointer;
  }
  > div {
    display: flex;
    align-items: center;
    height: 64px;
    font-size: 18px;
    color: $gray;
    font-weight: bold;
  }

  > div.active {
    color: $cerulean;

    border-bottom: 2px solid $cerulean;
  }

  > div:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: $break-point-md) {
  .mobile-home-nav {
    display: flex;
    width: 100%;
    height: 45px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 99;

    &:hover {
      cursor: pointer;
    }
    > div {
      display: flex;
      height: 100%;
      align-items: center;
      font-size: 18px;
      color: $gray;
      font-weight: bold;
    }

    > div.active {
      color: $cerulean;

      border-bottom: 2px solid $cerulean;
    }

    > div:not(:last-child) {
      margin-right: 20px;
    }
  }
  .dna-home-nav {
    display: none;
  }
}
