.barchart {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  text-align: center;
  .bar-title {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    padding: 0 4px;
    text-transform: uppercase;
  }
  .range {
    color: $mine_shaft;
    margin-top: 8px;
  }
  .bar {
    height: 24px;
    margin: 0 2px;
    background-color: $cerulean;
  }
  .bar.left-rounded {
    border-radius: 24px 0 0 24px;
    margin-right: 2px;
    background-color: $endeavour;
  }
  .bar.right-rounded {
    border-radius: 0 24px 24px 0;
    margin-right: 2px;
    background-color: $rouge;
  }
}

@media only screen and (max-width: $break-point-sm) {
  .barchart {
    .bar-title {
      font-size: 12px;
    }

    .range {
      font-size: 14px;
    }
  }
}
