.kit-tracker-alert-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border: 2px solid $itovi_dna_froly;
  border-radius: 8px 8px 0 0;
  box-shadow: inset 0 0 5px $itovi_dna_froly;

  svg {
    margin-left: 8px;

    &:hover {
      cursor: pointer;
      color: $itovi_dna_froly;
    }
  }
}

.single-kit-tracker {
  display: grid;
  grid-template-columns: 104px auto 0fr;
  grid-template-areas: "avatar heading btn-area";
  padding: 32px;
  .avatar {
    position: relative;
    grid-area: avatar;
    img {
      margin-right: 24px;
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }
  }

  .heading {
    grid-area: heading;
    align-self: center;
    color: $dove_gray;
    font-size: 18px;
    margin-right: 44px;
  }

  .status-container {
    display: flex;
    min-width: 185px;
    grid-area: btn-area;
    align-self: center;
    justify-content: flex-end;

    > div {
      > div:first-child {
        font-size: 14px;
        color: $dove_gray;
        margin-bottom: 4px;
      }

      > div:last-child {
        font-weight: bold;
        img {
          margin-right: 4px;
        }
      }

      .in-mail {
        color: $vivid_violet;
        img {
          filter: invert(24%) sepia(47%) saturate(3623%) hue-rotate(270deg)
            brightness(89%) contrast(87%);
        }
      }

      .received {
        color: $san_marino;
        img {
          filter: invert(21%) sepia(91%) saturate(955%) hue-rotate(174deg)
            brightness(98%) contrast(93%);
        }
      }

      .processing {
        color: $pacific_blue;
        img {
          filter: invert(36%) sepia(86%) saturate(1069%) hue-rotate(162deg)
            brightness(99%) contrast(93%);
        }
      }

      .traits-ready {
        color: $curious_blue;
        img {
          filter: invert(62%) sepia(87%) saturate(3120%) hue-rotate(158deg)
            brightness(94%) contrast(81%);
        }
      }
    }
    .warning-icon {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .single-kit-tracker {
    grid-template-columns: auto;
    grid-template-areas:
      "heading"
      "btn-area";
    grid-row-gap: 24px;

    .avatar {
      display: none;
    }

    .heading {
      margin: 0px;
      text-align: center;
    }

    .status-container {
      justify-content: center;

      div {
        text-align: center;
      }
    }
  }
}
