html,
body {
  overflow: hidden;
  font-family: Barlow, sans-serif !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
}

@font-face {
  font-family: Barlow, sans-serif !important;
  src: url("../assets/fonts/Barlow-SemiBold.otf") format("opentype");
  src: url("../assets/fonts/Barlow-Bold.otf") format("opentype");
  src: url("../assets/fonts/Barlow-Medium.otf") format("opentype");
  src: url("../assets/fonts/Barlow-Regular.otf") format("opentype");
}

.rectangle-image-placeholder {
  background-color: $dove_gray;
  width: 100%;
  height: 200px;
  border-radius: 8px 8px 0 0;
}

.layout-container {
  display: grid;
  width: 100vw;
  height: 100%;
  position: fixed;
  background: $iron;
  //grid-template-columns: 0fr 1fr;
  // grid-template-rows: auto;
  grid-template-rows: 65px auto;
  // grid-template-areas: "layout-item-2 layout-item-1" "layout-item-2 layout-item-3";
  grid-template-areas: "layout-item-1" "layout-item-2";

  .layout-item-1 {
    grid-area: layout-item-1;
    z-index: 99;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
  }

  .layout-item-2 {
    grid-area: layout-item-2;
    overflow-y: auto;
  }

  // .layout-item-3 {
  //   overflow-y: auto;
  //   grid-area: layout-item-3;
  // }
}

.layout-container.mobile-nav {
  grid-template-rows: 110px auto;
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  position: relative;

  .main-content {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
}

.btn-rounded {
  border-radius: 27px;
  text-align: center;
  font-size: 19px;
}

@media only screen and (max-width: $break-point-lg) {
  // .layout-container {
  //   grid-template-areas: "layout-item-2 layout-item-1" "layout-item-3 layout-item-3";

  //   .layout-item-2 {
  //     width: 64px;
  //   }
  // }

  .rectangle-image-placeholder {
    border-radius: 0;
  }
}
