.match-wellness-product {
  padding: $dna_section_wrapper_padding;
  background-color: #dae1e980;

  .match-wellness-heading {
    width: 80%;
    margin: auto;
  }

  > div {
    max-width: $dna_home_section;
    margin: auto;

    .title {
      margin-bottom: 12px;

      font-size: 28px;
      font-weight: bold;
      text-align: center;
    }

    .description {
      width: 80%;
      margin: auto;
      margin-bottom: 45px;
      color: $itovi_dna_astronaut_blue;
      font-size: 20px
    }

    .sections {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      max-width: 80%;
      margin: auto;
      > div {
        max-width: 350px;
        margin: 0 auto;
        padding: 16px;

        .image-container {
          width: 150px;
          margin: 0 auto 18px auto;
          position: relative;

          img {
            width: 100%;
          }
        }

        .title {
          font-size: 24px;
          color: $itovi_dna_royal_purple;
        }

        .section-description {
          text-align: center;
          color: $itovi_dna_astronaut_blue;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .match-wellness-product {
    > div {
      .description {
        padding: 0;
      }
      .sections {
        grid-template-columns: 1fr;

        > div {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .match-wellness-product {
    padding: 0 24px;

    > div {
      .title {
        font-size: 24px;
      }

      .description {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .match-wellness-product {
    padding: $dna_section_wrapper_padding;
    .match-wellness-heading {
      margin: 0;
      width: 100%;
      font-size: 36px;
    }
    > div {
      .title {
        font-size: 24px;
      }

      .description {
        width: 100%;
        margin: 0;
        margin-bottom: 32px;
        text-align: left;
      }

      .sections {
        max-width: 100%;
        > div:nth-child(3) {
          margin-bottom: 0;
        }
        > div {
          max-width: 100%;
          padding: 0;
          margin-bottom: 50px;

          .image-container {
            width: 126px;
          }

          .section-description {
            text-align: left;
          }
        }
      }
    }
  }
}
