.dna-privacy {
  padding: $dna_section_wrapper_padding;
  background: #fff;

  .dna-section-heading {
    margin-bottom: 25px;
    padding-bottom: 10px;
    font-size: 42px;
    font-weight: bold;
    width: 100%;
    color: $rouge;
    border-bottom: 2px solid $itovi_dna_midnight_blue;
  }

  > div {
    margin: 0 auto;
    max-width: $dna_home_section;

    .body {
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-template-rows: auto;
      grid-gap: 48px;
      max-width: 80%;
      margin-top: 32px;

      > div {
        position: relative;
        color: $itovi_dna_astronaut_blue;
        font-size: 20px;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .dna-privacy {
    > div {
      .body {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .dna-privacy {
    > div {
      .title {
        font-size: 30px;
      }
      .body {
        grid-gap: 24px;
        grid-template-columns: 1fr;

        > div {
          img {
            display: block;
            max-width: 200px;
            margin: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .dna-privacy {
    > div {
      .body {
        text-align: center;
        > div:first-child {
          img {
            display: block;
            width: 200px;
            margin: auto;
          }
        }
        grid-gap: 24px;
        grid-template-columns: 1fr;
      }
    }
  }
}
