.dna-banner {
  background: $mystic;
  background:url("../../../assets/iTOVi_DNA_Home_BG.jpg");
  background-size: cover;
  padding: 12px;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1880px;
    margin: auto;

    .banner-description {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      text-align: left;
      color: #FFF;

      div {
        margin-left:10vw;
        margin-right:2vw;
      }

      img {
        max-width:280px;
        margin-bottom: 30px;
      }

      > div:nth-child(2) {
        font-size: 60px;
        font-weight: bold;
        line-height: 60px;
        margin-bottom: 30px;
      }

      > div:nth-child(3) {
        font-size: 24px;
      }

      > div:last-child {
          margin-top: 33px;
          display: flex;
          flex-direction: column;
          align-items: left;

          button {
            font-size:1.5em;
            font-weight: bolder;
            width:200px;
            min-width: 220px;
            min-height: 60px;
            margin:12px 12px 12px 0px;
            border-radius: 0;
          }

          .button-primary {
            background-color: $itovi_dna_bright_green;
            &:hover {
              background-color: $itovi_dna_mint_green;
              border: none;
            }

            &:active,
            &:focus {
              background-color: $itovi_dna_mint_green;
              border: none;
            }
          }
          .button-outline-primary {
            background-color: #58C1A698;
            color: #FFF;
            border: 1px solid #FFF;

            &:hover {
              background-color: $itovi_dna_mint_green;
            }

            &:active,
            &:focus {
              background-color: $itovi_dna_mint_green;
            }
          }
      }
    }

    .banner-image {
      width: 100%;
      position: relative;
      padding: 24px;

      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1879px) {
  .dna-banner {
    padding: 12px 12px 24px 12px;
    > div {
      .banner-description {
        > div:nth-child(2) {
          font-size: 2.5vw;
          line-height: 2.5vw;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .dna-banner {
    > div {
      .banner-description {
        > div:nth-child(3) {
          font-size: 1.7vw;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .dna-banner {
    > div {
      flex-direction: column-reverse;
      justify-content: center;
      max-width: 90%;

      .banner-description {
        > div:nth-child(2) {
          font-size: 3.5vw;
        }
        > div:nth-child(3) {
          font-size: 2.5vw;
        }
        > div:last-child {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            font-size:2.5vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .dna-banner {
    > div {
      max-width: 100%;

      .banner-description {
        > div:nth-child(2) {
          font-size: 22px;
          line-height: 22px;
        }
        > div:nth-child(3) {
          font-size: 18px;
        }
        > div:last-child {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            font-size:18px;
          }
        }
      }
    }
  }
}
