.react-datepicker {
  .react-datepicker__triangle {
    border-bottom-color: #fff !important;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $dove_gray;
  }

  .react-datepicker__navigation--next {
    border-left-color: $dove_gray;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    &:focus {
      outline: 0;
    }
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #fff;

      .react-datepicker__current-month {
        font-weight: 400;
        margin-bottom: 8px;
      }

      select {
        color: #666666;
        border: 1px solid $silver;
        border-radius: 4px;
        padding: 2px;

        &:focus {
          outline: 0;
        }
      }
    }

    .react-datepicker__day {
      &:focus {
        outline: 0;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $light-primary;
    }
  }
}
