.publish-master-traits {
  .page-title {
    width: 100%;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    color: $nile_blue;
    text-transform: capitalize;
  }
  .traits-publish-list {
    .trait {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000029;

      &:not(last-child) {
        margin-bottom: 12px;
      }
      .trait-image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $gray;
        flex: none;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 70% 0;
          border-radius: 50%;
        }
      }
      .trait-name {
        width: 100%;
        padding: 0 0 0 12px;
        margin-right: 20px;
      }

      .trait-last-updated {
        width: 150px;
        margin-right: 8px;
        font-size: 14px;
        flex: none;
        > div:first-child {
          font-size: 16px;
          color: $cornflower-blue;
        }
      }
      .trait-status {
        width: 100px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .publish-master-traits {
    .traits-publish-list {
      .trait {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .trait-image {
          margin-bottom: 12px;
        }
        .trait-name {
          margin-bottom: 12px;
          margin-right: 0;
        }

        .trait-last-updated {
          margin-bottom: 12px;
          margin-right: 0;
          font-size: 14px;
        }
      }
    }
  }
}
