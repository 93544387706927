.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  backdrop-filter: blur(7px);

  .modal-card {
    position: absolute;
    top: 141px;
    margin-bottom: 38px;
    display: flex;
    flex-direction: column;
    max-width: 472px;
    width: 100%;
    padding: 24px;
    background: #fff;
    border-radius: 9px;
    box-shadow: 1px 1px 6px #0000004d;
  
    .modal-card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 22px;
  
      .header-title {
        text-transform: capitalize;
        font-weight: bold;
        font-size: 22px;
        margin-right: 12px;
        color: $eden;
      }
  
      .dismiss {
        width: 16px;
        height: 16px;
        background: url("../assets/close_icon.svg");
        background-repeat: no-repeat;
        &:hover {
          background: url("../assets/close_icon_hover.svg");
          cursor: pointer;
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .modal-wrapper {
    .modal-card {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      top: 46px;

      .modal-card-header {
        .header-title {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-xs) {
  .modal-wrapper {
    .modal-card {
      padding: 24px 12px;
    }
  }
}