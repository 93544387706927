footer {
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  padding: 16px;
  background-color: $astronaut_blue;
  color: #fff;

  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > div {
      margin-right: 8px;
    }

    > div:not(:last-child) {
      border-right: 1px solid #fff;
    }

    a {
      color: #fff;
      margin-right: 8px;
      &:hover {
        text-decoration: none;
        color: $pigeon_post;
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    color: $silver;

    p {
      margin: 0;
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  footer {
    .footer-links {
      a {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  footer {
    .footer-links {
      flex-direction: column;
      align-items: center;
      a {
        font-size: 16px;
        padding-right: 0;
      }

      > div {
        margin-right: 0;
      }

      > div:not(:last-child) {
        border-right: none;
        margin-bottom: 12px;
      }
    }

    .copyright {
      text-align: center;
      font-size: 14px;
    }
  }
}
