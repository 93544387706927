.report-summary {
  .back-button {
    color: $nile_blue;
    font-size: 24px;
    margin-bottom: 12px;
    cursor: pointer;
  }

  .report-summary-title {
    padding: 16px;
    text-transform: capitalize;
    font-size: 18px;
    border-bottom: 1px solid $pigeon_post;
    color: $nile_blue;
  }

  .report-image-container {
    width: 100%;
    max-height: 500px;
    overflow: hidden;

    .report-image {
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
  }

  .report-summary-content {
    padding: 16px;

    h5 {
      color: $cornflower-blue;
      margin-bottom: 12px;
    }

    p {
      color: $gun_powder;
    }
  }

  .read-more-button {
    margin-top: 22px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    color: $primary;
    font-weight: bold;
  }
}

@media only screen and (max-width: $break-point-lg) {
  .report-summary,
  .report-summary .report-image-container .report-image {
    border-radius: 0;
  }
}