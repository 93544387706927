.redeem-code-helper {
  .redeem-input-container {
    margin-bottom: 25px;
    margin-right: 20px;

    .error-description {
      font-size: 13px;
      color: $thunderbird;
    }

    .email-code {
      outline: none !important;
      border-radius: 4px;
      height: 40px;
      width: 152px;
    }

    .dont-have-code {
      font-size: 13px;
      color: $eastern_blue;
      text-align: right;
      padding-top: 4px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .input-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      font-size: 12px;
      color: $dove_gray;
      > div:first-child {
        margin-right: 12px;
      }
    }

    .error-message {
      margin-top: 12px;
      font-size: 13px;
      color: $thunderbird;
    }

    .redeem-response {
      display: flex;
      align-self: flex-start;
      font-size: 12px;
      font-weight: bold;
      text-align: right;

      &.error {
        color: $thunderbird;
        img {
          filter: invert(18%) sepia(66%) saturate(2724%) hue-rotate(342deg)
            brightness(105%) contrast(120%);
        }
      }

      &.success {
        color: $christi;
        img {
          filter: invert(76%) sepia(70%) saturate(3295%) hue-rotate(46deg)
            brightness(92%) contrast(87%);
        }
      }

      img,
      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .redemption-code {
      text-transform: uppercase;
    }

    input {
      width: 100%;
      outline: none !important;
      border: 1px solid $silver;
      border-radius: 4px;
      padding: 8px 12px;
      height: 42px;

      &:focus {
        outline: none !important;
        border: 1px solid $cerulean;
        border-radius: 4px;
      }
    }
    input.error {
      border: 1px solid $thunderbird;
      box-shadow: 0px 0px 5px #cc111166;
    }
    input.success {
      border: 1px solid $christi;
    }
  }

  .redeem-description {
    margin: 0 16px 25px 3px;
    font-size: 13px;
    color: $dove_gray;

    .redeem-text-link {
      font-size: 13px;
      color: $eastern_blue;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: lighten($eastern_blue, 5%);
      }
    }
  }

  .redeem-actions {
    display: flex;
    justify-content: flex-end;

    .redeem-buttons {
      display: flex;
      align-items: center;
      :first-child {
        margin-right: 12px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .redeem-code-helper {
    .redeem-actions {
      display: flex;
      flex-direction: column;

      .redeem-text-link {
        margin-bottom: 16px;
      }

      .redeem-buttons {
        display: flex;
        justify-content: flex-end;
        :first-child {
          margin-right: 12px;
        }
      }
    }
  }
}