.notification-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100;
  padding: 18px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;

  .toggle-banner {
    &:hover {
      cursor: pointer;
      color: $emperor;
    }
  }
}

.notification-banner.success {
  background-color: lighten($forest_green, $amount: 52);
  border: 1px solid $forest_green;
  color: $forest_green;
  font-weight: bold;
}

.notification-banner.danger {
  background-color: lighten($bright_red, $amount: 60);
  border: 1px solid $bright_red;
  color: $bright_red;
  font-weight: bold;
}
