.master-trait-form {
  .trait-form-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .form-status {
      font-size: 20px;
      font-weight: bold;
      color: $cornflower-blue;
    }
  }

  .trait-form-section {
    .form-section-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $pigeon_post;

      > div:first-child {
        font-size: 18px;
        color: $nile_blue;
        font-weight: bold;
      }
    }

    .form-input-container {
      padding: 16px;

      .report-section {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $nile_blue;
      }

      .checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #000;
          border-radius: 2px;

          &:hover {
            cursor: pointer;
          }

          > div {
            width: 12px;
            height: 12px;
            border-radius: 2px;
          }
        }

        .checkbox.active {
          > div {
            background-color: $attol;
          }
        }
      }

      .form-inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        .form-input-field {
          display: flex;
          flex-grow: 1;
          margin-right: 8px;
        }
      }

      .form-inline > * {
        margin-top: 8px;
      }

      .gene {
        margin-bottom: 12px;
      }
    }

    .score-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $silver;

      &:not(:first-child) {
        margin-top: 16px;
      }

      > div:first-child {
        color: $mine_shaft;
      }
      > div:nth-child(2) {
        button:first-child {
          margin-right: 12px;
        }
      }
    }
  }

  .delete-trait {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;

    .delete-message {
      margin-right: 16px;
      font-size: 16px;
      font-weight: bold;
      color: $emperor;
    }

    .confirm-delete {
      margin-right: 12px;
    }
  }

  .related-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $silver;
    padding-bottom: 12px;
    min-height: 60px;
    margin-bottom: 16px;
    text-align: center;

    .related-heading-title {
      font-size: 16px;
      color: $cornflower-blue;
      font-weight: bold;
    }
  }

  .related-link {
    padding-bottom: 16px;
    border-radius: 8px;
    border: 1px solid $silver;
    margin-bottom: 24px;
    .related-remove {
      margin-left: 16px;
    }

    textarea {
      height: 100px;
    }
  }

  .active-score-dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px;

    > div:first-child {
      margin-right: 8px;
      font-weight: bold;
    }

    > div:last-child {
      width: 200px;
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .master-trait-form {
    .delete-trait {
      padding: 0 12px;
    }

    .active-score-dropdown {
      margin-right: 12px;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .master-trait-form {
    .trait-form-section {
      .score-item {
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;

          align-content: flex-end;
          button {
            width: 100%;
          }
          button:first-child {
            margin: 0 0 8px 0;
          }
        }
      }
    }

    .active-score-dropdown {
      flex-direction: column;
      margin: 0;
      margin-bottom: 24px;
      justify-content: center;
    }
  }
}
