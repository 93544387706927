.nutrient-summary {
  border-radius: 8px;

  .nutrient-summary-heading {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
    }
    > div:nth-child(2) {
      display: flex;
      border-radius: 8px 8px 0px 0px;
      background-color: rgba(0, 0, 0, 0.2);
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      font-size: 32px;
    }
  }

  .nutrient-summary-content {
    padding: 24px;

    .nutrient-sub-heading {
      margin-bottom: 16px;
      font-size: 22px;
      color: $cornflower-blue;
    }

    .nutrient-summary-table {
      width: 100%;

      .nutrient-table-heading {
        display: flex;
        flex-direction: row;
        background-color: $seashel;
        text-transform: uppercase;

        > div {
          padding: 14px 10px;
          width: 100%;
          color: $cornflower-blue;
          font-size: 14px;
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
      }

      .nutrient-table-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 60px;
        border-bottom: 1px solid $silver;
        color: $cornflower-blue;
        padding: 12px 16px;

        > div {
          width: 100%;
          line-height: 18px;
          &:not(:last-child) {
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .nutrient-summary {
    .nutrient-summary-content {
      .nutrient-summary-table {
        .nutrient-table-heading {
          display: none;
        }
        .nutrient-table-row {
          flex-direction: column;

          > div {
            padding: 0;

            &:not(:last-child) {
              margin-bottom: 8px;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .nutrient-summary {
    border-radius: 0;

    .nutrient-summary-heading {
      > div:nth-child(2) {
        border-radius: 0;
      }

      img {
        border-radius: 0;
      }
    }
  }
}
