.how-it-works-wrapper {
  background-color: #dae1e980;
  padding: $dna_section_wrapper_padding;

  .how-it-works {
    width: 100%;
    max-width: $dna_home_section;
    margin: auto;

    .steps-container {
      width: 100%;
      margin: 30px 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 12px;

        > div:first-child {
          width: 200px;
          height: 200px;
          position: relative;
          margin-bottom: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        > div:not(:last-child) {
          color: $itovi_dna_royal_purple;
          font-weight: bold;
          font-size: 72px;
        }

        > div:last-child {
          color: $itovi_dna_astronaut_blue;
          font-size: 20px;
        }
      }

      > div:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .how-it-works-wrapper {
    .how-it-works {
      width: 100%;

      .title {
        margin-top: 12px;
      }

      .steps-container {
        flex-direction: column;
        align-items: center;

        > div {
          width: 100%;
          max-width: 475px;
          padding: 0;
          margin-bottom: 24px;
        }

        > div:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .how-it-works {
    .title {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: $break-point-md) {
  .how-it-works {
    .title {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .how-it-works {
    width: 100%;
  }
}

@media only screen and (max-width: $break-point-xs) {
  .how-it-works {
    .steps-container {
      margin: 24px auto;
    }
  }
}
