.report-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 6px #00000029;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  .flip-image {
    transform: scaleX(-1);
  }

  .header {
    width: 100%;
    position: relative;
    border-radius: 8px 8px 0 0;
    color: #fff;
    height: 0;
    overflow:hidden;
    padding-top: 303px / 1200px * 100%;

    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
      background-color: $gray;
      height:100%;
      top:0;
      left:0;
      position: absolute;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent);
      border-radius: 8px 8px 0 0;
      padding: 12px 24px;
      font-size: 30px;
      font-weight: bold;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 16px 20px;
    border-radius: 0 0 8px 8px;
    height: 100%;
    background: #fff;

    > div:last-child {
      margin-top: 12px;
      display: flex;
      flex-direction: row;

      button:last-child {
        margin-left: 12px;
      }
    }

    .generating-container {
      display: flex;
      flex-direction: row;

      > div:first-child {
        font-size: 16px;
        color: $dove_gray;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .report-card {
    border-left: none;
    border-right: none;

    .header {
      border-radius: 0;

      > div {
        font-size: 22px;
        border-radius: 0;
      }

      img {
        border-radius: 0;
      }
    }

    .body {
      border-radius: 0;
      padding: 24px 12px;
    }
  }
}
