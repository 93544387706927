.master-trait-nav {
  .trait-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 16px 12px;
    border-bottom: 1px solid $pigeon_post;

    &:hover {
      cursor: pointer;
    }

    > div:first-child {
      font-size: 20px;
      font-weight: bold;
      color: $cornflower-blue;

      svg {
        display: none;
      }
    }
  }

  .master-trait-list {
    padding: 0 12px 12px 12px;

    .trait {
      &:not(:first-child) {
        margin-bottom: 8px;
        border-top: 1px solid $silver;
        margin-top: 8px;

        > div {
          margin-top: 8px;
        }
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;

        &:hover {
          background-color: $seashel;
          cursor: pointer;
        }

        .image {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $gray;
          flex: none;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 70% 0;
            border-radius: 50%;
          }
        }

        .name {
          width: 100%;
          padding: 0 0 0 12px;
        }

        .icon {
          padding-left: 10px;

          img {
            width: 20px;
            height: 20px;
            filter: invert(54%) sepia(48%) saturate(0%) hue-rotate(134deg)
              brightness(91%) contrast(87%);
          }
        }
      }
    }

    .trait.active {
      > div {
        background-color: $seashel;
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .master-trait-nav {
    .trait-nav-header {
      margin-bottom: 0;
      border: none;

      > div:first-child {
        svg {
          display: inline;
          margin: 0 0 0 8px;
        }
      }
    }

    .master-trait-list {
      display: none;
    }
  }

  .master-trait-nav.active {
    .trait-nav-header {
      margin-bottom: 12px;
      border-bottom: 1px solid $pigeon_post;

      > div:first-child {
        svg {
          display: inline;
          margin: 0 0 0 8px;
        }
      }
    }

    .master-trait-list {
      display: block;
    }
  }
}
