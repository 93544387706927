.trait-summary-container {
  .trait-summary-header {
    width: 100%;
    position: relative;
    border-radius: 8px 8px 0 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0 0;
    }
    > div:nth-child(2) {
      display: flex;
      border-radius: 8px 8px 0px 0px;
      background-color: rgba(0, 0, 0, 0.2);
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      font-size: 32px;
    }
  }

  .trait-summary-nav {
    display: flex;
    flex-direction: row;
    padding: 0px 16px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: $dove_gray;
    border-bottom: 1px solid $silver;

    > div {
      padding: 16px;

      &:hover {
        cursor: pointer;
      }
    }

    > div.active {
      color: $mine_shaft;
      font-weight: bold;
      border-bottom: 2px solid $curious_blue;
    }
  }
  .trait-nav-content {
    padding: 30px 24px;

    .trait-nav-header {
      font-size: 18px;
      font-weight: bold;
      color: $cornflower-blue;
      margin-bottom: 30px;
    }

    .trait-score-description {
      margin-top: 12px;
      border-bottom: 1px solid $silver;
    }

    .related-container {
      padding: 0 16px;

      .genes-description {
        color: $cornflower-blue;
        border-bottom: 1px solid $silver;
        margin-bottom: 16px;
      }

      .links-container {
        .link-title {
          font-weight: bold;
          font-size: 18px;
          color: $cornflower-blue;
          margin-bottom: 16px;
        }

        ul {
          padding: 0 0 0 25px;

          li {
            padding: 0 0 16px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $break-point-lg) {
  .trait-summary-container {
    .trait-summary-header {
      border-radius: 0;

      img {
        border-radius: 0;
      }
      > div:nth-child(2) {
        border-radius: 0;
      }
    }
  }
}

@media only screen and (max-width: $break-point-sm) {
  .trait-summary-container {
    .trait-summary-header {
      > div:nth-child(2) {
        font-size: 20px;
      }
    }

    .trait-summary-nav {
      font-size: 14px;
    }

    .trait-nav-content {
      padding: 16px;

      .related-container {
        padding: 0;
      }
    }
  }
}
